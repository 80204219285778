API.Search = {};

API.Search.ParseResults = function(data) {
  $("#search-results").html("");
  $("#search-results").append(`<h6>Výsledky vyhledávání v kamerách <small>(${data.data.cameras.length} výsledků)</small></h6>`);
  if (data.data.cameras.length) {
    var collection = $("<div class='collection'>");
    data.data.cameras.forEach(function(element) {
      collection.append(`<a href="${element.URL}" class="collection-item">${element.Name}</a>`)
    });
    $("#search-results").append(collection);
  }else{
    $("#search-results").append(`<hr style='border-color:rgba(0,0,0,.1)'>`)
  }
   $("#search-results").append(`<h6>Výsledky vyhledávání v článcích <small>(${data.data.articles.length} výsledků)</small></h6>`)
   if (data.data.articles.length) {
     var collection = $("<div class='collection'>");
     data.data.articles.forEach(function(element) {
       collection.append(`<a href="${element.URL}" class="collection-item">${element.Name}</a>`)
     });
     $("#search-results").append(collection);
   }
}

API.Search.SetupAjax = function() {

  // capture submit
  $('form#search-form').submit(function() {
    $theForm = $(this);

    // send xhr request
    $.ajax({
      type: $theForm.attr('method'),
      url: $theForm.attr('action'),
      data: $theForm.serialize(),
      success: function(data) {
        API.Search.ParseResults(data);
      }
    });

    // prevent submitting again
    return false;
  });
}

$(function() {
  API.Search.SetupAjax();
})
